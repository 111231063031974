import '../styles/sitemap.scss';

import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import  call1 from '../images/call-24-px.png';
import  email1 from '../images/ic-email-24-px.png';

const SiteMapPage = () => (
  <Layout>
    <SEO
      title='Bubble Sitemap'
      canonicalUrl='https://www.getmybubble.com/sitemap/'/>

    <div className="wrapper">
      <div className="termsconditionwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>SiteMap</h2>
            </div>

            <div className="col-12">
              <div className="linklist">
                <ul>
                  <li>
                    <h4>Products</h4>

                    <ul>
                      <li><Link to="/homeowners-insurance/">Homeowners Insurance</Link></li>

                      <li><Link to="/life-insurance/">Life Insurance</Link></li>
                    </ul>
                  </li>
                </ul>

                <ul>
                  <li>
                    <h4>Company</h4>

                    <ul>
                      <li><Link to="/about/">About Bubble</Link></li>

                      <li><Link to="/our-story/">Our story</Link></li>

                      <li><Link to="/our-team/">Our team</Link></li>

                      <li><Link to="/license-numbers/">Licence Number</Link></li>

                      <li><Link to="/privacy-policy/">Privacy Policy</Link></li>

                      <li><Link to="/accessibility/">Accessibility</Link></li>

                      <li><Link to="/terms-of-service/">Term of Services</Link></li>

                      <li><Link to="https://partnerwithus.getmybubble.com/">Partner with Us</Link></li>

                      {/* <li><Link to="/">Pricing</Link></li> */}

                      <li><h4>Contact us</h4>

                        <ul>
                          <li><img src={call1} alt="call" />(833) 900-BUBB</li>

                          <li className="nocaps"><img src={email1} alt="email" />

                            <a href="mailto:hello@getmybubble.com" target="_blank" rel="noopener noreferrer">hello@getmybubble.com</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul>
                  <li>
                    <h4>Resources</h4>

                    <ul>
                      <li>
                        <a target='_blank' href="https://www.getmybubble.com/blog" rel="noreferrer noopener">Blog</a>
                      </li>

                      <li><Link to="/faq/">FAQs</Link></li>

                      <li><Link to="/">Our Partners</Link></li>

                      <li><h4>Tools</h4>

                        <ul>
                          <li><Link to="https://app.getmybubble.com/lifescore/">LifeScore</Link></li>

                          <li><Link to="https://app.getmybubble.com/homescore/">HomeScore</Link></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul>
                  <li>
                    <h4>Let’s Connect</h4>

                    <ul>
                      <li><Link to="https://www.facebook.com/GetMyBubble/">Facebook</Link></li>

                      <li><Link to="https://twitter.com/getmybubble/">Twitter</Link></li>

                      <li><Link to="https://www.linkedin.com/company/getmybubble/">linkedin</Link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
export default SiteMapPage;
